import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/InstallingSparkLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Installing Spark`}</h1>
    <p>{`Welcome to the Spark Design System! Here we’ll help you
create digital products with the Rocket Mortgage system of
patterns and components.`}</p>
    <p>{`To help get you started installing Spark, we created
starter repositories for each of the frameworks you might be using:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-html"
        }}><inlineCode parentName="a">{`spark-starter-html`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-angular"
        }}><inlineCode parentName="a">{`spark-starter-angular`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-react"
        }}><inlineCode parentName="a">{`spark-starter-react`}</inlineCode></a></li>
    </ul>
    <p>{`You’ll notice each repository will include a branch highlighting
Spark in various implementation states. Refer to the descriptions
to learn more about each.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Branch`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`main`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design system and prerequisites installed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kitchen-sink`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Design system and prerequisites installed. Includes test page with components used for testing latest system version.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`without-spark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boilerplate app without Spark installed.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Don't forget to check out our installation guides to help walk
you through setting up Spark in different environments!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      